import React from "react";
import { BackLink } from "../../components/BackLink";
import { ServiceHeader } from "../../components/ServiceHeader";
import { BetterCta } from "../../components/BetterCta";
import { ServiceDetail } from "../../components/ServiceDetail";
import { PricingBlock } from "../../components/PricingBlock";
import { Link } from "react-router-dom";
import img1 from "../../assets/nokti/nokti1.jpg";
import img2 from "../../assets/nokti/nokti2.jpg";
import img3 from "../../assets/nokti/nokti3.jpg";
import img4 from "../../assets/nokti/nokti4.jpg";

export const NailsPage = () => {
  const imgs = [img3, img1, img2];
  const arms = [
    {
      title: "OPI SPA manikir",
      price: "1000 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
    {
      title: "Gel lak",
      price: "1500 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
    {
      title: "Ojačavanje prirodnih",
      price: "2000 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
    {
      title: "Collagen spa tretman",
      price: "2500 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
  ];

  const nails = [
    {
      title: "Izlivanje noktiju S",
      price: "2000 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Izlivanje noktiju M",
      price: "2200 RSD",
      description: "Trajanje 75 minuta po tretmanu.",
    },
    {
      title: "Izlivanje noktiju L",
      price: "2500 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
    {
      title: "Izlivanje noktiju XL",
      price: "2800 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
  ];

  const additional = [
    {
      title: "Crtanje (po noktu)",
      price: "100 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "French",
      price: "500 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "Nakit (po noktu)",
      price: "200 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "Skidanje tudjeg rada",
      price: "1000 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
  ];
  const text =
    "Nega ruku je važan deo celokupne nege tela i estetskog izgleda. Naši tretmani pružaju kompletnu negu ruku i noktiju, ovi tretmani se fokusiraju na hidrataciju, oblikovanje i ojačavanje noktiju, kao i revitalizaciju kože ruku. Različite tehnike i proizvodi se koriste kako bi se postigao najbolji mogući efekat, pružajući rukama negovan i lep izgled. Nega ruku je važna kako bi se očuvala mekoća, glatkoća i lepota kože ruku, te se preporučuje kao deo redovne nege tela. Nega noktiju je bitan deo estetske nege koja doprinosi ukupnom izgledu i samopouzdanju. Naši tretmani za nokte se fokusiraju na oblikovanje, produžavanje i ojačavanje noktiju. Sa različitim trajanjem i cenama, ovi tretmani pružaju personalizovanu negu noktiju koja odgovara željenoj dužini i stilu. Kroz kvalitetne proizvode i stručnu tehniku, ovi tretmani osiguravaju lep i negovan izgled noktiju, čineći ih sjajnim dodatkom Vašem celokupnom izgledu. Nega noktiju je važna kako bi se očuvala lepota i zdravlje noktiju, te se preporučuje kao deo redovne nege tela.";
  return (
    <div className="pt-[72px]">
      <ServiceHeader name="Nega ruku i manikir" hero={img4} />
      <BackLink />
      <ServiceDetail name="Nega ruku i manikir" text={text} imgs={imgs} />
      <div className="px-8 mt-12">
        <PricingBlock services={arms} title="Nega ruku" />
        <PricingBlock services={nails} title="Nokti" />
        <PricingBlock services={additional} title="Dodatno" />
      </div>
      <div className="px-8 mt-8 mb-12">
        <Link
          to="/cenovnik"
          className="border-2 border-accent text-accent px-4 py-2 rounded font-bold"
        >
          Pogledajte sve usluge
        </Link>
      </div>
      <BetterCta />
    </div>
  );
};
