import React, { useState, useEffect, useCallback } from "react";
import { Title } from "./Title";
import img1 from "../assets/slike/hipnotic1.webp";
import img2 from "../assets/slike/hipnotic2.webp";
import img3 from "../assets/slike/hipnotic3.webp";
import img4 from "../assets/slike/hipnotic4.webp";
import img5 from "../assets/slike/hipnotic5.webp";
import img6 from "../assets/slike/hipnotic6.webp";
import img7 from "../assets/slike/hipnotic7.webp";
import img8 from "../assets/slike/hipnotic8.jpg";
import img10 from "../assets/slike/hipnotic10.webp";
import img11 from "../assets/slike/hipnotic11.webp";
import img12 from "../assets/slike/hipnotic12.webp";
import img14 from "../assets/slike/hipnotic14.jpg";
import img15 from "../assets/slike/hipnotic15.webp";
import img16 from "../assets/slike/hipnotic16.webp";
import img18 from "../assets/slike/hipnotic18.webp";
import img20 from "../assets/slike/hipnotic20.webp";
import img19 from "../assets/slike/hipnotic19.webp";

const imgs = [
  {
    url: img1,
    alt: "Hipnotic",
  },
  {
    url: img2,
    alt: "Hipnotic Kragujevac",
  },
  {
    url: img7,
    alt: "Spa Kragujevac",
  },
  {
    url: img8,
    alt: "Beauty salon Kragujevac",
  },
  {
    url: img10,
    alt: "Spa and beauty",
  },
  {
    url: img11,
    alt: "Salon lepote kg",
  },
  {
    url: img3,
    alt: "Masaže kg",
  },
  {
    url: img12,
    alt: "Masaže kragujevac",
  },
  {
    url: img14,
    alt: "Hipnotic rs",
  },
  {
    url: img15,
    alt: "Hipnotic beauty studio kragujevac",
  },
  {
    url: img18,
    alt: "Najbolji salon lepote kg",
  },
  {
    url: img19,
    alt: "Hipnotic 034",
  },
  {
    url: img4,
    alt: "Salon lepote 034",
  },
  {
    url: img20,
    alt: "Salon lepote kragujevac kg",
  },
  {
    url: img6,
    alt: "Piling kg",
  },
  {
    url: img5,
    alt: "Kriopoliza kg",
  },
  {
    url: img16,
    alt: "Hipnotik manikir kg",
  },
];

export const Gallery = () => {
  const [lightboxIndex, setLightboxIndex] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const handleShowAll = () => setShowAll(true);

  const openLightbox = (index) => {
    setLightboxIndex(index);
  };

  const closeLightbox = () => {
    setLightboxIndex(null);
  };

  const goToPrev = useCallback(() => {
    if (lightboxIndex === 0) {
      setLightboxIndex(imgs.length - 1);
    } else {
      setLightboxIndex(lightboxIndex - 1);
    }
  }, [lightboxIndex]);

  const goToNext = useCallback(() => {
    if (lightboxIndex === imgs.length - 1) {
      setLightboxIndex(0);
    } else {
      setLightboxIndex(lightboxIndex + 1);
    }
  }, [lightboxIndex]);

  const shownImgs = showAll ? imgs : imgs.slice(0, 12);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        lightboxIndex !== null && closeLightbox();
      }
      if (event.keyCode === 37) {
        // Left arrow key
        lightboxIndex !== null && goToPrev();
      } else if (event.keyCode === 39) {
        // Right arrow key
        lightboxIndex !== null && goToNext();
      }
    };

    const handleClickOutside = (event) => {
      if (event.target.classList.contains("lightbox")) {
        closeLightbox();
      }
    };

    document.addEventListener("keydown", handleEscape);
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [goToNext, goToPrev, lightboxIndex]);

  return (
    <div className="px-8 bg-smoke py-8">
      <Title title="Naša" rest="galerija" className="pt-8" />
      <p className="text-gray-600 text-lg font-lexend my-8 text-justify">
        Zakoračite u naš svet lepote i opuštanja.{" "}
        <span className="text-accentonlight">Hipnotic</span> - beauty and spa
        pruža vrhunsko iskustvo u nezi tela i uma. Uživajte u našim ekskluzivnim
        tretmanima i osetite harmoniju između lepote i unutrašnjeg blagostanja.
        Doživite trenutke opuštanja koji će vas odvesti na put ka celovitom
        blagostanju.
      </p>
      <div className="grid grid-cols-1 grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4 lg:gap-8">
        {shownImgs.map((img, index) => (
          <div
            key={index}
            className="h-[150px] sm:h-[200px] md:h-[250px] lg:h-[300px] object-cover"
          >
            <img
              src={img.url}
              alt={img.alt}
              loading="lazy"
              className="cursor-pointer block w-full h-full sm:max-w-xs md:max-w-none object-cover"
              onClick={() => openLightbox(index)}
            />
          </div>
        ))}
      </div>
      {!showAll && (
        <div className="flex justify-center">
          <button
            className="my-8 px-4 py-2 bg-black hover:bg-accent font-medium rounded z-10 h-12 text-white"
            onClick={handleShowAll}
          >
            Prikaži sve
          </button>
        </div>
      )}
      {lightboxIndex !== null && (
        <div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-75 z-10"
          onClick={closeLightbox}
        >
          <div
            className="relative "
            onClick={(event) => event.stopPropagation()}
          >
            <button
              className="fixed top-0 right-0 m-4 text-white text-3xl"
              onClick={closeLightbox}
            >
              &times;
            </button>
            <img
              src={imgs[lightboxIndex].url}
              alt={imgs[lightboxIndex].alt}
              className="max-w-full max-h-full"
            />
            <button
              className="absolute top-0 left-0 flex items-center justify-center text-white text-3xl w-1/2 h-full bg-transparent"
              onClick={goToPrev}
            />
            <button
              className="absolute top-0 right-0 flex items-center justify-center text-white text-3xl w-1/2 h-full bg-transparent"
              onClick={goToNext}
            />
            <button
              className="absolute top-1/2 transform -translate-y-1/2 left-0 m-4 text-white text-3xl"
              onClick={goToPrev}
            >
              &lt;
            </button>
            <button
              className="absolute top-1/2 transform -translate-y-1/2 right-0 m-4 text-white text-3xl"
              onClick={goToNext}
            >
              &gt;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
