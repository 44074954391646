import React from "react";
import img1 from "../assets/indiba/indiba4.jpg";
import img2 from "../assets/indiba/indiba2.webp";
import img3 from "../assets/indiba/indiba3.webp";
import img4 from "../assets/indiba/indiba1.webp";
import img5 from "../assets/indiba/indiba5.jpg";
import patternImage from "../assets/indiba/background.png";
import { Title } from "./Title";

export const FeaturedService = () => {
  const services = [
    {
      img: img1,
      title: "Podmlađivanje lica",
      description:
        "INDIBA pomaže u regeneraciji kolagenskih i elastičnih vlakana, čineći kožu punijom, smanjujući bore i poboljšavajući znakove starenja.",
      link: "#book",
    },
    {
      img: img2,
      title: "Zatezanje kože",
      description:
        "INDIBA tretmani zatezanja kože stimulišu proizvodnju kolagena, pospešujući čvrstoću i mlađi izgled sa neinvazivnim, dugotrajnim rezultatima.",
      link: "#book",
    },
    {
      img: img4,
      title: "Podmlađivanje tela",
      description:
        "Uravnotežuje ćelije, povećava unutrašnju temperaturu i aktivira metabolizam, eliminišući masnoće i celulit bez oštećenja tkiva.",
      link: "#book",
    },
    {
      img: img5,
      title: "Tretmani za kosu",
      description:
        "Naši tretmani za kosu povećavaju protok krvi i snabdevanje kiseonikom kako bi se sprečilo opadanje kose, ojačalo vlakno kose i stimulisao rast nove kose.",
      link: "#book",
    },
    {
      img: img3,
      title: "Spa tretmani",
      description:
        "Kombinacija INDIBA tehnologije sa tehnikama ručne masaže deluje na dubljem nivou, opušta mišiće i povećava eliminaciju toksina.",
      link: "#book",
    },
  ];

  return (
    <section
      className="py-16 bg-white shadow-lg rounded-lg bg-repeat bg-center"
      style={{ backgroundImage: `url(${patternImage})` }}
    >
      <div className="container mx-auto px-4">
        <Title title="Indiba" rest="Deep Beauty" className="mb-10" />
        <div className="flex flex-wrap">
          {services.map((service, index) => (
            <div key={index} className="w-full lg:w-1/3 md:w-1/2 p-4">
              <div className="bg-white rounded-lg shadow-lg transform transition duration-300 hover:-translate-y-2">
                <img
                  loading="lazy"
                  src={service.img}
                  alt={service.title}
                  className="w-full h-48 object-cover rounded-t-lg"
                />
                <div className="p-6">
                  <h2 className="text-2xl font-semibold mb-3 text-accent font-lexend">
                    {service.title}
                  </h2>
                  <p className="text-gray-600 mb-4 font-lexend">
                    {service.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
