import React from "react";
import { BackLink } from "../components/BackLink";
import { ServiceHeader } from "../components/ServiceHeader";
import { BetterCta } from "../components/BetterCta";
import { FAQ } from "../components/FAQ";

export const FaqPage = () => {
  return (
    <div className="pt-[72px]">
      <ServiceHeader name="Najčešća pitanja" />
      <BackLink />
      <FAQ />
      <BetterCta />
    </div>
  );
};
