import React, { useState, useRef, useEffect } from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaEnvelope,
  FaPhone,
  FaBars,
  FaTimes,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Heart } from "./Heart";

export const NavBar = () => {
  const { pathname } = useLocation();
  const [showTretmaniSubMenu, setShowTretmaniSubMenu] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleTretmaniSubMenu = () => {
    setShowTretmaniSubMenu(!showTretmaniSubMenu);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Close the menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <nav
      id="nav"
      className={classNames(
        "flex items-center justify-between px-4 md:px-8 bg-[rgba(0,0,0,0.5)] absolute top-0 left-0 right-0 z-[100]",
        {
          "bg-accentlight": pathname !== "/",
        }
      )}
    >
      <div className="flex">
        <div className="flex flex-row gap-1">
          <Link className="flex justify-center items-center py-4" to="/">
            <h1 className="text-3xl md:text-5xl text-white font-playfair mb-1 md:mb-2 font-regular">
              I
            </h1>
            <Heart className="h-8 w-8 md:w-16 fill-white md:h-16" />
            <div className="flex flex-col text-center">
              <h2 className="text-white text-lg font-bold text-center font-whisper ml-2 -mb-2 hidden md:flex ">
                beauty & massage centar
              </h2>
              <h1 className="text-3xl md:text-4xl text-white font-whisper md:border-b-2 font-bold">
                Hipnotic
              </h1>
            </div>
          </Link>
        </div>
      </div>

      <div className="md:hidden flex items-center">
        <button
          onClick={toggleMenu}
          className="text-white z-[200]"
          aria-label="Open Mobile Menu Button"
        >
          {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      </div>

      <div
        ref={menuRef}
        className={classNames(
          "fixed top-0 right-0 h-full bg-black bg-opacity-80 z-[9999] w-[300px] transform transition-transform duration-300 ease-in-out",
          {
            "translate-x-0": menuOpen,
            "translate-x-full": !menuOpen,
          }
        )}
      >
        <div className="relative flex flex-col items-center justify-center h-full gap-4 p-4">
          <button
            onClick={toggleMenu}
            aria-label="Close Navigation Menu"
            className="absolute top-4 right-4 text-white z-[10000]"
          >
            <FaTimes size={24} />
          </button>
          <Link
            to="/"
            className={classNames("text-xl font-medium", {
              "text-accentondark": pathname === "/",
              "text-white": pathname !== "/",
              "cursor-default": pathname === "/",
            })}
            onClick={toggleMenu}
          >
            • Početna
          </Link>
          <Link
            to="/cenovnik"
            className={classNames("text-xl font-medium", {
              "text-accentondark": pathname === "/cenovnik",
              "text-white": pathname !== "/cenovnik",
              "cursor-default": pathname === "/cenovnik",
            })}
            onClick={toggleMenu}
          >
            • Cenovnik
          </Link>
          <Link
            to="/tretmani/masaze"
            className={classNames("text-xl font-medium", {
              "text-accentondark": pathname === "/tretmani/masaze",
              "text-white": pathname !== "/tretmani/masaze",
              "cursor-default": pathname === "/tretmani/masaze",
            })}
            onClick={toggleMenu}
          >
            • Masaže
          </Link>
          <Link
            to="/tretmani/tretmani-lica"
            className={classNames("text-xl font-medium", {
              "text-accentondark": pathname === "/tretmani/tretmani-lica",
              "text-white": pathname !== "/tretmani/tretmani-lica",
              "cursor-default": pathname === "/tretmani/tretmani-lica",
            })}
            onClick={toggleMenu}
          >
            • Tretmani lica
          </Link>
          <Link
            to="/tretmani/indiba"
            className={classNames("text-xl font-medium", {
              "text-accentondark": pathname === "/tretmani/indiba",
              "text-white": pathname !== "/tretmani/indiba",
              "cursor-default": pathname === "/tretmani/indiba",
            })}
            onClick={toggleMenu}
          >
            • Indiba tretmani
          </Link>
          <Link
            to="/tretmani/pedikir"
            className={classNames("text-xl font-medium", {
              "text-accentondark": pathname === "/tretmani/pedikir",
              "text-white": pathname !== "/tretmani/pedikir",
              "cursor-default": pathname === "/tretmani/pedikir",
            })}
            onClick={toggleMenu}
          >
            • Pedikir
          </Link>
          <Link
            to="/tretmani/nega-ruku"
            className={classNames("text-xl font-medium", {
              "text-accentondark": pathname === "/tretmani/nega-ruku",
              "text-white": pathname !== "/tretmani/nega-ruku",
              "cursor-default": pathname === "/tretmani/nega-ruku",
            })}
            onClick={toggleMenu}
          >
            • Nega ruku i manikir
          </Link>
          <Link
            to="/najcesca-pitanja"
            className={classNames("text-xl font-medium", {
              "text-accentondark": pathname === "/najcesca-pitanja",
              "text-white": pathname !== "/najcesca-pitanja",
              "cursor-default": pathname === "/najcesca-pitanja",
            })}
            onClick={toggleMenu}
          >
            • Najčešća pitanja
          </Link>

          <a
            href="https://www.facebook.com/hiponotic"
            className="text-white text-2xl"
            onClick={toggleMenu}
            aria-label="Link to our Facebook page"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.instagram.com/hipnotic_beauty_studio/"
            className="text-white text-2xl"
            aria-label="Link to our Instagram page"
            onClick={toggleMenu}
          >
            <FaInstagram />
          </a>
          <a
            href="mailto:info@hipnotic.rs"
            className="text-white text-xl flex items-center cursor-pointer"
            onClick={toggleMenu}
          >
            <FaEnvelope className="mr-1" />
            <span>info@hipnotic.rs</span>
          </a>
          <a
            href="tel:0631129917"
            className="text-white text-xl flex items-center cursor-pointer"
            onClick={toggleMenu}
          >
            <FaPhone className="mr-1 ml-2" />
            <span>063 112 99 17</span>
          </a>
        </div>
      </div>

      <div className="hidden md:flex">
        <div
          className="px-4 text-white hover:bg-accent rounded hover:text-white font-medium -mt-4 pt-4 -mb-4 pb-4 mr-6 relative"
          onMouseEnter={toggleTretmaniSubMenu}
          onMouseLeave={toggleTretmaniSubMenu}
        >
          Tretmani
          {showTretmaniSubMenu && (
            <div className="absolute left-0 top-[50px] bg-black text-white py-2 px-4 shadow-lg z-20 w-[200px]">
              <div className="flex flex-col gap-2">
                <Link
                  to="/tretmani/masaze"
                  className={classNames("hover:text-accent", {
                    "text-accent": pathname === "/tretmani/masaze",
                  })}
                >
                  Masaže
                </Link>
                <Link
                  to="/tretmani/tretmani-lica"
                  className={classNames("hover:text-accent", {
                    "text-accent": pathname === "/tretmani/tretmani-lica",
                  })}
                >
                  Tretmani lica
                </Link>
                <Link
                  to="/tretmani/indiba"
                  className={classNames("hover:text-accent", {
                    "text-accent": pathname === "/tretmani/indiba",
                  })}
                >
                  Indiba tretmani
                </Link>
                <Link
                  to="/tretmani/pedikir"
                  className={classNames("hover:text-accent", {
                    "text-accent": pathname === "/tretmani/pedikir",
                  })}
                >
                  Pedikir
                </Link>
                <Link
                  to="/tretmani/nega-ruku"
                  className={classNames("hover:text-accent", {
                    "text-accent": pathname === "/tretmani/nega-ruku",
                  })}
                >
                  Nega ruku i manikir
                </Link>
              </div>
            </div>
          )}
        </div>
        <Link
          to="/cenovnik"
          className={classNames(
            "px-4 text-white hover:bg-accent rounded font-medium -mt-4 pt-4 -mb-4 pb-4 mr-6",
            {
              "bg-accent": pathname === "/cenovnik",
            }
          )}
        >
          Cenovnik
        </Link>
        <Link
          to="/najcesca-pitanja"
          className={classNames(
            "px-4 text-white hover:bg-accent rounded font-medium -mt-4 pt-4 -mb-4 pb-4",
            {
              "bg-accent": pathname === "/najcesca-pitanja",
            }
          )}
        >
          Najčešća pitanja
        </Link>
      </div>
      <div className="hidden md:flex items-center gap-2 text-white font-light align-middle">
        <a
          href="https://www.facebook.com/hiponotic"
          aria-label="Link to our Facebook page"
          className="hover:text-accent mr-4 w-[48px] h-[48px] grid place-items-center"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://www.instagram.com/hipnotic_beauty_studio/"
          className="hover:text-accent mr-4 w-[48px] h-[48px] grid place-items-center"
          aria-label="Link to our Instagram page"
        >
          <FaInstagram />
        </a>
        <a
          href="mailto:info@hipnotic.rs"
          className="hidden lg:flex hover:text-accent flex items-center cursor-pointer"
        >
          <FaEnvelope className="mr-1" />
          <span>info@hipnotic.rs</span>
        </a>
        <a
          href="tel:0631129917"
          className="hover:text-accent flex items-center cursor-pointer"
        >
          <FaPhone className="mr-1 ml-2" />
          <span>063 112 99 17</span>
        </a>
      </div>
    </nav>
  );
};
