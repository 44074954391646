import React from "react";
import "./ServiceHeader.css";
import bgImage from "../assets/hipnoticHero.webp";

export const ServiceHeader = ({ name, hero, className }) => {
  const heroImg = hero ? hero : bgImage;
  return (
    <div
      className={`service-header text-2xl md:text-3xl lg:md:text-4xl text-white font-lexend bg-top lg:bg-center ${className}`}
      style={{
        backgroundImage: `url(${heroImg})`,
      }}
    >
      {name}
    </div>
  );
};
