import React from "react";
import left from "../assets/cta/cta-left.png";
import flower from "../assets/cta/cta-flower.png";
import img1 from "../assets/slike/hipnotic10.webp";
import img2 from "../assets/slike/hipnotic11.webp";

export const BetterCta = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center gap-4 relative bg-accentonlight">
      <div className="md:w-1/2 md:pr-4 px-8 h-[440px] grid items-center">
        <div>
          <div className="font-whisper text-white text-4xl md:text-6xl relative z-50">
            Zakažite Vaš termin
          </div>
          <div className="font-lexend text-white text-3xl md:text-5xl relative z-50 pt-4 pb-8">
            Prepustite se hipnotičkom luksuzu koje Vaše telo zaslužuje
          </div>
          <div className="relative z-50 mt-4">
            <a
              href="tel:0631129917"
              className="border-2 border-white text-white px-4 py-2 rounded font-bold"
            >
              063 112 99 17
            </a>
          </div>
        </div>
      </div>
      <div className="hidden md:flex md:w-1/2 md:pr-4 px-8 h-[440px] relative">
        <div className="border-[20px] border-white w-[480px] h-[280px] bg-red-200 rotate-6 absolute -top-[40px] -left-[220px] md:left-[70px] drop-shadow-2xl z-50">
          <img
            src={img1}
            alt="Hipnotic kg salon lepote"
            className="object-cover w-full h-full"
            loading="lazy"
          />
        </div>
        <div className="border-[20px] border-white w-[360px] h-[215px] bg-red-200 -rotate-17 absolute top-[50px] md:top-[200px] -left-[100px] md:left-[160px] drop-shadow-2xl z-50">
          <img
            src={img2}
            alt="Hipnotic kg salon lepote"
            className="object-cover w-full h-full"
            loading="lazy"
          />
        </div>
        <div className="absolute z-50 left-[150px] md:left-[400px] -top-[50px] md:top-[90px] w-[150px] h-[150px] cta-flower">
          <img src={flower} alt="hipnotic beauty kg" loading="lazy" />
        </div>
      </div>
      <div className="absolute -left-[10px] top-0 h-[440px] opacity-50">
        <img
          className="shape-move"
          src={left}
          alt="hipnotic kragujevac"
          loading="lazy"
        ></img>
      </div>
    </div>
  );
};
