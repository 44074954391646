import React from "react";
import "./OurTeam.css";
import { Title } from "./Title";
import img1 from "../assets/tim1.webp";

const OurTeam = () => {
  // const teamMembers = [
  //   {
  //     id: 1,
  //     name: "Jelena Ribaric",
  //     instagram: "@johndoe",
  //     image: "https://via.placeholder.com/200",
  //   },
  //   {
  //     id: 2,
  //     name: "Jane Smith",
  //     instagram: "@janesmith",
  //     image: "https://via.placeholder.com/200",
  //   },
  //   {
  //     id: 3,
  //     name: "Janko Stanic",
  //     instagram: "@johndoe",
  //     image: "https://via.placeholder.com/200",
  //   },
  //   {
  //     id: 4,
  //     name: "Jelena Stanic",
  //     instagram: "@janesmith",
  //     image: "https://via.placeholder.com/200",
  //   },
  //   {
  //     id: 5,
  //     name: "Peta Osoba",
  //     instagram: "@johndoe",
  //     image: "https://via.placeholder.com/200",
  //   },
  //   {
  //     id: 26,
  //     name: "Sesta osoba",
  //     instagram: "@janesmith",
  //     image: "https://via.placeholder.com/200",
  //   },
  // ];

  // const handleMouseEnter = (id) => {
  //   const member = document.getElementById(`member-${id}`);
  //   member.classList.add("hovered");
  // };

  // const handleMouseLeave = (id) => {
  //   const member = document.getElementById(`member-${id}`);
  //   member.classList.remove("hovered");
  // };

  // const [currentIndex, setCurrentIndex] = useState(0);
  // const [screenSize, setScreenSize] = useState(window.innerWidth);
  // const [animationClass, setAnimationClass] = useState("");

  // const updateScreenSize = () => {
  //   setScreenSize(window.innerWidth);
  //   setCurrentIndex(0);
  // };

  // window.addEventListener("resize", updateScreenSize);
  // const reviewsPerPage = screenSize < 600 ? 1 : screenSize < 1020 ? 2 : 3;

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     updateScreenSize();
  //     const nextIndex = currentIndex + reviewsPerPage;
  //     if (nextIndex >= teamMembers.length) {
  //       setCurrentIndex(0);
  //     } else {
  //       setCurrentIndex(nextIndex);
  //     }
  //     setAnimationClass("slide-left");
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, [currentIndex, teamMembers.length, reviewsPerPage]);

  // const visibleReviews = teamMembers.slice(
  //   currentIndex,
  //   currentIndex + reviewsPerPage
  // );

  return (
    <div className="px-8 py-8 bg-smoke pb-8">
      <div className="flex flex-col md:flex-row items-center justify-center gap-4 mb-12">
        <div className="md:w-1/2 md:pr-4">
          <Title title="Naš" rest="tim" />
          <p className="text-justify font-lexend mb-8">
            Naš tim u Hipnotic Beauty and Spa je sastavljen od stručnjaka
            posvećenih Vašoj lepoti i dobrobiti. Svi članovi našeg tima su
            iskusni profesionalci sa bogatim iskustvom u svojim oblastima,
            spremni da Vam pruže vrhunsku uslugu i pomognu Vam da postignete
            željene rezultate. Uz stručnost i ljubaznost, naš tim će Vam pružiti
            ugodno iskustvo i pobrinuti se da se osećate opušteno i zadovoljno
            tokom Vašeg boravka u našem salonu.
          </p>
        </div>
        <div className="w-full md:w-1/2 h-full">
          <img
            className="h-full w-full object-contain rounded-md max-h-[500px]"
            src={img1}
            loading="lazy"
            alt="Hipnotic Kragujevac timska slika"
          />
        </div>
      </div>
      {/* <div className="our-team flex flex-col items-center md:flex-row overflow-hidden">
        {visibleReviews.map((member) => (
          <div
            key={member.id}
            id={`member-${member.id}`}
            className={`team-member h-[200px] w-[200px] lg:h-[300px] lg:w-[300px] ${animationClass}`}
            onMouseEnter={() => handleMouseEnter(member.id)}
            onMouseLeave={() => handleMouseLeave(member.id)}
          >
            <div className="member-image">
              <img src={member.image} alt={member.name} />
            </div>
            <div className="member-info font-lexend">
              <p className="text-accent">{member.name}</p>
              <p>{member.instagram}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-12">
        {Array.from(
          { length: Math.ceil(teamMembers.length / reviewsPerPage) },
          (_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index * reviewsPerPage)}
              className={`mx-1 w-4 h-4 rounded-full ${
                currentIndex === index * reviewsPerPage
                  ? "bg-accent"
                  : "bg-gray-400"
              }`}
            />
          )
        )}
      </div> */}
    </div>
  );
};

export default OurTeam;
