import { Header } from "./Header";
import { IoIosCheckmarkCircleOutline as Checkmark } from "react-icons/io";
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import { Title } from "./Title";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ImagesGrid } from "./ImagesGrid";
import img1 from "../assets/wishpro/wishpro1.webp";
import img2 from "../assets/wishpro/wishpro2.jpg";
import img3 from "../assets/wishpro/wishpro3.webp";
import bgImg from "../assets/wishpro/background.webp";

const images = [
  { src: img2, alt: "WishPRO tretmani kragujevac" },
  { src: img1, alt: "Wish PRO Kragujevac" },
  { src: img3, alt: "Wish Kragujevac" },
];

export const WishPro = () => {
  const [selected, setSelected] = useState(1);

  const handleNext = () => {
    setSelected((prev) => {
      if (prev === 3) {
        return 1;
      } else {
        return prev + 1;
      }
    });
  };
  const handlePrev = () => {
    setSelected((prev) => {
      if (prev === 1) {
        return 3;
      } else {
        return prev - 1;
      }
    });
  };
  const title1 = "ESTETSKA REVOLUCIJA U NAŠEM SALONU";

  return (
    <div
      className="px-8 py-12 bg-repeat bg-center"
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      <Title title="Wish pro" rest="tretmani" className="mb-12" />
      <div className="flex flex-col md:flex-row items-center justify-center gap-4 mb-12">
        <div className="md:w-1/2 md:pr-4">
          <Header text={title1} className="text-3xl mb-4" />
          <p className="font-lexend text-justify text-lg">
            Dobrodošli u svet vrhunske nege kože sa WISHPro tretmanima! Naš
            inovativni uređaj za tretman kože kombinuje četiri različite
            dokazane tehnologije, omogućavajući vam da doživite izvanredne
            rezultate za samo 15 minuta neinvazivnog tretmana.
          </p>
          <p className="text-xl font-semibold text-accent font-lexend my-4">
            Kako funkcioniše WISHPro?
          </p>
          <p className="font-lexend text-justify text-lg">
            WISHPro koristi napredne kozmetičke kapsule koje se integrišu sa
            našim uređajem, pružajući prilagođene tretmane za sve tipove kože.
            Bez obzira da li želite podmlađivanje, hidrataciju ili osveženje
            kože, WISHPro vam može pomoći da postignete željene rezultate brzo i
            efikasno.
          </p>
          <div>
            <div className="font-lexend flex gap-2 items-center mb-2 mt-6">
              <Checkmark className="fill-accent w-6 h-6 mt-[1px] shrink-0" />
              <strong className="text-accent">Brzi rezultati:</strong> Vidite i
              osećajte razliku za samo 15 minuta
            </div>
            <div className="font-lexend flex gap-2 items-center mb-2">
              <Checkmark className="fill-accent w-6 h-6 mt-[1px] shrink-0" />
              <strong className="text-accent">Neinvazivni pristup:</strong> Bez
              igala, bolova i vremena za oporavak
            </div>
            <div className="font-lexend flex gap-2 items-center">
              <Checkmark className="fill-accent w-6 h-6 shrink-0 mt-[1px]" />
              <strong className="text-accent">
                Prilagođeni tretmani:
              </strong>{" "}
              Izaberite kapsulu koja najbolje odgovara Vama
            </div>
          </div>
          <div className="flex gap-4 mt-8">
            <div
              className="p-4 border border-accent rounded-full w-14 h-14 flex justify-center items-center cursor-pointer"
              onClick={handlePrev}
            >
              <FaArrowLeftLong className="fill-accent" />
            </div>
            <div
              className="p-4 border border-accent rounded-full w-14 h-14 flex justify-center items-center cursor-pointer"
              onClick={handleNext}
            >
              <FaArrowRightLong className="fill-accent" />
            </div>
          </div>
          <div className="mt-8">
            <Link
              to="/cenovnik"
              className="border border-accent text-accent px-4 py-2 rounded font-semibold"
            >
              Pogledajte sve usluge
            </Link>
          </div>
        </div>
        <div className="md:w-1/2">
          <ImagesGrid
            images={images}
            selected={selected}
            onSelect={setSelected}
          />
        </div>
      </div>
    </div>
  );
};
