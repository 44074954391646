import React, { useEffect, useState } from "react";
import "./Cursor.css";

/* Extra */
export const Cursor = () => {
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setCursorPos({ x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    document.body.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.body.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div
      className="custom-cursor"
      style={{ left: cursorPos.x, top: cursorPos.y }}
    >
      <div className="outer-circle">
        {/* <div className="inner-circle"></div> */}
      </div>
    </div>
  );
};
