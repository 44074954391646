import * as React from "react";

export const Heart = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="400.000000pt"
    height="400.000000pt"
    viewBox="0 0 400.000000 400.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
      //   fill="#000000"
      stroke="none"
    >
      <path d="M2602 3519 c-308 -37 -598 -261 -743 -575 -34 -73 -89 -238 -89 -265 0 -25 18 -1 45 58 46 104 159 266 243 352 266 268 617 345 901 196 128 -68 213 -178 247 -322 23 -99 14 -287 -21 -413 -49 -177 -156 -369 -305 -545 -74 -86 -311 -323 -505 -504 -331 -309 -515 -561 -606 -829 -45 -132 -35 -134 31 -5 131 258 280 419 740 802 544 451 736 699 838 1081 21 81 25 118 26 250 1 174 -11 235 -68 355 -117 248 -426 401 -734 364z" />
      <path d="M1130 3210 c-175 -22 -357 -146 -438 -297 -162 -305 -43 -756 286 -1081 41 -40 160 -142 266 -226 202 -162 311 -268 373 -363 20 -32 39 -60 40 -62 2 -2 6 -2 9 2 12 12 -63 152 -125 234 -34 46 -128 144 -209 220 -311 291 -387 381 -477 567 -67 137 -94 233 -101 356 -11 170 32 289 145 401 142 141 328 182 520 116 96 -34 168 -78 253 -156 38 -35 71 -62 73 -59 10 10 -82 132 -141 186 -134 124 -309 183 -474 162z" />
    </g>
  </svg>
);
