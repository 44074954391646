import React from "react";
import { Homepage } from "./pages/Homepage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "./components/Layout";
import { PricingPage } from "./pages/PricingPage";
import { FaqPage } from "./pages/FaqPage";
import { FacialTreatmentsPage } from "./pages/subpages/FacialTreatmentsPage";
import { FaceMassagePage } from "./pages/subpages/FaceMassagePage";
import { NailsPage } from "./pages/subpages/NailsPage";
import { IndibaPage } from "./pages/subpages/IndibaPage";
import { PedicurePage } from "./pages/subpages/PedicurePage";

function App() {
  return (
    <div className="relative">
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route index element={<Homepage />} />
            <Route path="cenovnik" element={<PricingPage />} />
            <Route path="najcesca-pitanja" element={<FaqPage />} />
            <Route
              path="tretmani/tretmani-lica"
              element={<FacialTreatmentsPage />}
            />
            <Route path="tretmani/masaze" element={<FaceMassagePage />} />
            <Route path="tretmani/indiba" element={<IndibaPage />} />
            <Route path="tretmani/pedikir" element={<PedicurePage />} />
            <Route path="tretmani/nega-ruku" element={<NailsPage />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
