import React from "react";
import classNames from "classnames";

export const Header = ({
  text,
  className,
  onClick,
  dangerouslySetInnerHTML,
}) => {
  return (
    <h1
      onClick={onClick}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      className={classNames(
        "font-extralight font-lexend text-accent font-medium",
        className
      )}
    >
      {text}
    </h1>
  );
};
