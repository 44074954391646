// src/components/ProductShowcase.js
import React from "react";
import logo1 from "../assets/partneri/logo1.png";
import logo2 from "../assets/partneri/logo2.png";
import logo3 from "../assets/partneri/logo3.webp";
import logo4 from "../assets/partneri/logo4.png";
import logo5 from "../assets/partneri/logo5.webp";
import logo6 from "../assets/partneri/logo6.png";
import logo7 from "../assets/partneri/logo7.webp";
import logo8 from "../assets/partneri/logo8.png";
import logo9 from "../assets/partneri/logo9.png";
import logo10 from "../assets/partneri/logo10.webp";
import patternImage from "../assets/indiba/background.png";
import { Title } from "./Title";

const products = [
  { id: 1, name: "Product 1", logo: logo1 },
  { id: 2, name: "Product 1", logo: logo2 },
  { id: 3, name: "Product 1", logo: logo3 },
  { id: 4, name: "Product 1", logo: logo4 },
  { id: 5, name: "Product 1", logo: logo5 },
  { id: 6, name: "Product 1", logo: logo6 },
  { id: 7, name: "Product 1", logo: logo7 },
  { id: 8, name: "Product 1", logo: logo8 },
  { id: 9, name: "Product 1", logo: logo9 },
  { id: 10, name: "Product 1", logo: logo10 },
  // Add more products as needed
];

export const PartnerShowcase = () => {
  return (
    <div
      className="py-12 px-8"
      style={{ backgroundImage: `url(${patternImage})` }}
    >
      <Title title="Naši" rest="partneri" className="mb-16" />
      <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-5 gap-8">
        {products.map((product) => (
          <div key={product.id} className="flex flex-col items-center">
            <img
              loading="lazy"
              src={product.logo}
              className="w-24 h-24 object-contain mb-4"
              alt="beauty i spa partner logo"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
