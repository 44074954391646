import React from "react";
import { FaEnvelope, FaFacebookF, FaInstagram, FaPhone } from "react-icons/fa";
import { FooterButton } from "./FooterButton";

export const Footer = () => {
  return (
    <footer className="bg-white px-8 py-4">
      <div className="flex">
        <div className="mx-auto mt-4">
          <div className="flex space-x-4 mb-8">
            <FooterButton url="tel:0631129917" aria-label="Our phone number">
              <FaPhone size={24} />
            </FooterButton>
            <FooterButton url="mailto:info@hipnotic.rs" aria-label="Our email">
              <FaEnvelope size={24} />
            </FooterButton>
            <FooterButton
              url="https://www.facebook.com/hipnotic"
              aria-label="Link to our Facebook page"
            >
              <FaFacebookF size={22} />
            </FooterButton>
            <FooterButton
              url="https://www.instagram.com/hipnotic_beauty_studio/"
              aria-label="Link to our Instagram page"
            >
              <FaInstagram size={24} />
            </FooterButton>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center border-t pt-4">
        <p className="text-xs text-gray-600 mb-4 font-lexend">
          © 2024 Hipnotic <span className="hidden md:inline">Kragujevac</span>
        </p>

        <p className="text-xs text-gray-600 mb-4 font-lexend">
          Created by JJSolutions
        </p>
      </div>
    </footer>
  );
};
