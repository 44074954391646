import React, { useState } from "react";
import { Title } from "./Title";
import flower from "../assets/hipnotic-flowah.png";
import { PricingBlock } from "./PricingBlock";

export const Pricing = () => {
  const [search, setSearch] = useState("");
  const tretmaniLica = [
    {
      title: "Higijenski tretman",
      price: "3500 RSD",
      description: "",
    },
    {
      title: "Higijenski tretman sa fitopilingom",
      price: "4500 RSD",
      description: "",
    },
    {
      title: "Higijenski sa hemijskim pilingom",
      price: "4000 RSD",
      description: "",
    },
    {
      title: "Fitopiling",
      price: "4000 RSD",
      description: "",
    },
    {
      title: "Radiotalasni lifting",
      price: "3500 RSD",
      description: "",
    },
    {
      title: "Hydrafacial",
      price: "4500 RSD",
      description: "",
    },
    {
      title: "Ultrazvučni piling",
      price: "2500 RSD",
      description: "",
    },
    {
      title: "Derma pen",
      price: "6000 RSD",
      description: "",
    },
    {
      title: "Prx-t33",
      price: "6000 RSD",
      description: "",
    },
    {
      title: "Retinol stop akne",
      price: "4000 RSD",
      description: "",
    },
    {
      title: "Retinol anti age",
      price: "6000 RSD",
      description: "",
    },
    {
      title: "Wish pro",
      price: "7000 RSD",
      description: "",
    },
    {
      title: "Zk peeling",
      price: "5000 RSD",
      description: "",
    },
    {
      title: "Hydra intesive",
      price: "4500 RSD",
      description: "",
    },
    {
      title: "Masaža lica",
      price: "1500 RSD",
      description: "",
    },
  ];

  const depilacijaVosak = [
    {
      title: "Depilacija celih nogu",
      price: "1300 RSD",
      description: "",
    },
    {
      title: "Depilacija nogu-prepone",
      price: "1500 RSD",
      description: "",
    },
    {
      title: "Depilacija 1/2 nogu",
      price: "700 RSD",
      description: "",
    },
    {
      title: "Depilacija ruku",
      price: "700 RSD",
      description: "",
    },
    {
      title: "Depilacija brazilka",
      price: "800 RSD",
      description: "",
    },
    {
      title: "Depilacija stomaka",
      price: "500 RSD",
      description: "",
    },
    {
      title: "Depilacija ledja",
      price: "1000 RSD",
      description: "",
    },
    {
      title: "Depilacija pazuha",
      price: "400 RSD",
      description: "",
    },
    {
      title: "Depilacija prepona",
      price: "600 RSD",
      description: "",
    },
  ];

  const depilacijaSecernaPasta = [
    {
      title: "Depilacija celih nogu",
      price: "1700 RSD",
      description: "",
    },
    {
      title: "Depilacija nogu-prepone",
      price: "2000 RSD",
      description: "",
    },
    {
      title: "Depilacija nogu-brazilka",
      price: "2400 RSD",
      description: "",
    },
    {
      title: "Depilacija 1/2 nogu",
      price: "900 RSD",
      description: "",
    },
    {
      title: "Depilacija ruku",
      price: "800 RSD",
      description: "",
    },
    {
      title: "Depilacija pazuha",
      price: "400 RSD",
      description: "",
    },
    {
      title: "Depilacija brazilka",
      price: "1000 RSD",
      description: "",
    },
    {
      title: "Depilacija prepona",
      price: "800 RSD",
      description: "",
    },
    {
      title: "Depilacija ledja",
      price: "1500 RSD",
      description: "",
    },
    {
      title: "Depilacija grudi",
      price: "1500 RSD",
      description: "",
    },
  ];

  const arms = [
    {
      title: "OPI SPA manikir",
      price: "1000 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
    {
      title: "Gel lak",
      price: "1500 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
    {
      title: "Ojačavanje prirodnih",
      price: "2000 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
    {
      title: "Collagen spa tretman",
      price: "2500 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
  ];

  const nails = [
    {
      title: "Izlivanje noktiju S",
      price: "2000 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Izlivanje noktiju M",
      price: "2200 RSD",
      description: "Trajanje 75 minuta po tretmanu.",
    },
    {
      title: "Izlivanje noktiju L",
      price: "2500 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
    {
      title: "Izlivanje noktiju XL",
      price: "2800 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "French",
      price: "500 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "Skidanje tudjeg rada",
      price: "1000 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
  ];

  const hidratation = [
    {
      title: "Mezoterapija",
      price: "3500 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Vitamin energy ericson lab",
      price: "4500 RSD",
      description: "Trajanje 75 minuta po tretmanu.",
    },
    {
      title: "Hydra itensive ericson lab",
      price: "4500 RSD",
      description: "Trajanje 90 minuta po tretmanu.",
    },
    {
      title: "Hidrodermoabrazija",
      price: "4000 RSD",
      description: "Trajanje 90 minuta po tretmanu.",
    },
    {
      title: "Oxygen tretman",
      price: "4500 RSD",
      description: "Trajanje 75 minuta po tretmanu.",
    },
  ];

  const scars = [
    {
      title: "Derma pen",
      price: "6000 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Me line",
      price: "8000 RSD",
      description: "Trajanje 75 minuta po tretmanu.",
    },
    {
      title: "Perfection ericson lab",
      price: "4500 RSD",
      description: "Trajanje 80 minuta po tretmanu.",
    },
    {
      title: "Enzimacid ericson lab",
      price: "4500 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
  ];

  const masks = [
    {
      title: "Skinjection red mask",
      price: "2000 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "Hydra intensive blue mask",
      price: "2000 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "Caviar black mask",
      price: "2000 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "Retinol mask",
      price: "2000 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
  ];

  const instant = [
    {
      title: "Golden Glow",
      price: "3000 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "Caviar tretman",
      price: "3000 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
    {
      title: "Botox tretman",
      price: "4000 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Collagen tretman",
      price: "4000 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
  ];

  const additional = [
    {
      title: "Korekcija obrva",
      price: "600 RSD",
      description: "",
    },
    {
      title: "Depilacija nausnica",
      price: "200 RSD",
      description: "",
    },
    {
      title: "Farbanje obrva",
      price: "300 RSD",
      description: "",
    },
    {
      title: "Lash lift",
      price: "2000 RSD",
      description: "",
    },
    {
      title: "Brows lift",
      price: "2000 RSD",
      description: "",
    },
    {
      title: "Puder obrve",
      price: "150 EUR",
      description: "",
    },
    {
      title: "Korekcija puder tehnike obrva",
      price: "75 EUR",
      description: "",
    },
    {
      title: "Trajna šminka usana",
      price: "150 EUR",
      description: "",
    },
    {
      title: "Korekcija trajne šminke usana",
      price: "75 EUR",
      description: "",
    },
  ];

  const negaStopala = [
    {
      title: "Estetski pedikit",
      price: "2000 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Estetski pedikir-gel lak",
      price: "2500 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Medicinski pedikir 1",
      price: "2500 RSD",
      description: "Trajanje 90 minuta po tretmanu.",
    },
    {
      title: "Medicinski pedikir 2",
      price: "3000 RSD",
      description: "Trajanje 90 minuta po tretmanu.",
    },
    {
      title: "Medicinski pedikir-gel lak",
      price: "3000 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Antimikotik pedikir",
      price: "3500 RSD",
      description: "Trajanje 90 minuta po tretmanu.",
    },
    {
      title: "Antimikotik obrada noktiju",
      price: "2500 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Saniranje uraslog nokta",
      price: "1000 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "Saniranje žulja, kurjeg oka",
      price: "1000 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "Ortoksija proteza 1 uraslog nokta",
      price: "3000 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Tamponada silikonska",
      price: "1000 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "Gel lak na noktima",
      price: "1500 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Obrada noktiju",
      price: "1000-1500 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "Obrada stopala",
      price: "1000-1500 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "Lakiranje noktiju",
      price: "500 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
  ];

  const indibaDeepBeauty = [
    {
      title: "Indiba lice",
      price: "6000 RSD",
      description: "Trajanje 90 minuta po tretmanu.",
    },
    {
      title: "Indiba lice-vrat-dekolte",
      price: "8000 RSD",
      description: "Trajanje 90 minuta po tretmanu.",
    },
    {
      title: "Indiba očna zona",
      price: "4000 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
    {
      title: "Indiba podbradak",
      price: "4000 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
    {
      title: "Indiba kosa",
      price: "6000 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
  ];

  const indibaTelo = [
    {
      title: "Indiba noge",
      price: "6000 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Indiba stomak",
      price: "4000 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Indiba ruke",
      price: "4000 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
    {
      title: "Indiba noge-stomak",
      price: "8000 RSD",
      description: "Trajanje 90 minuta po tretmanu.",
    },
    {
      title: "Indiba kolena",
      price: "4000 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
    {
      title: "Indiba gluteusi",
      price: "5000 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
  ];

  const masaze = [
    {
      title: "Masaža 30'",
      price: "1300 RSD",
      description: "",
    },
    {
      title: "Masaža 60'",
      price: "2500 RSD",
      description: "",
    },
    {
      title: "Masaža trudnica",
      price: "2000 RSD",
      description: "",
    },
    {
      title: "Anticelulitna masaža",
      price: "1400 RSD",
      description: "",
    },
    {
      title: "Masaža Terapeutska 30'",
      price: "1500 RSD",
      description: "",
    },
    {
      title: "Masaža terapeutska 60'",
      price: "3000 RSD",
      description: "",
    },
    {
      title: "Limfna drenaža",
      price: "1400 RSD",
      description: "",
    },
    {
      title: "Maderoterapija",
      price: "1400 RSD",
      description: "",
    },
    {
      title: "Vakum masaža",
      price: "1400 RSD",
      description: "",
    },
    {
      title: "Piling celog tela",
      price: "2000 RSD",
      description: "",
    },
    {
      title: "Tesla body former",
      price: "2000 RSD",
      description: "",
    },
    {
      title: "Kavitacija",
      price: "1500 RSD",
      description: "",
    },
    {
      title: "Presoterapija",
      price: "1500 RSD",
      description: "",
    },
    {
      title: "Orient thermo tretman",
      price: "2000 RSD",
      description: "",
    },
    {
      title: "Kedrova sauna",
      price: "1500 RSD",
      description: "",
    },
  ];

  return (
    <div className="px-8 py-12 relative bg-smoke">
      <Title title="Naš" rest="cenovnik" className="mb-4" />
      <p className="font-lexend mb-6">
        U našem studiu moguće je izvršiti plaćanje gotovinom, kao i platnim
        karticama. <br />
        ** Klik na naziv grupe otvara stranicu te grupe sa dodatnim
        informacijama.
      </p>
      <input
        type="search"
        className="border border-accentlight text-black text-2xl active:border-accent py-2 px-4 rounded-md mb-8 font-lexend w-full max-w-[320px]"
        placeholder="🔍 Pretražite tretmane"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <PricingBlock
        services={masaze}
        title="Masaže"
        search={search}
        link="/tretmani/masaze"
      />
      <PricingBlock
        services={tretmaniLica}
        title="Tretmani lica"
        link="/tretmani/tretmani-lica"
        search={search}
      />
      <PricingBlock
        services={instant}
        title="Instant tretmani"
        search={search}
      />
      <PricingBlock services={masks} title="Maske za lice" search={search} />
      <PricingBlock
        services={hidratation}
        title="Hidratacija"
        search={search}
      />
      <PricingBlock
        services={scars}
        title="Ožiljci i hiperpigmentacija"
        search={search}
      />
      <PricingBlock
        services={indibaDeepBeauty}
        title="Indiba Deep Beauty"
        link="/tretmani/indiba"
        search={search}
      />
      <PricingBlock
        services={indibaTelo}
        title="Indiba Telo"
        search={search}
        link="/tretmani/indiba"
      />
      <PricingBlock
        services={depilacijaVosak}
        title="Depilacija vosak"
        search={search}
      />
      <PricingBlock
        services={depilacijaSecernaPasta}
        title="Depilacija šećerna pasta"
        search={search}
      />
      <PricingBlock
        services={negaStopala}
        title="Nega stopala"
        link="/tretmani/pedikir"
        search={search}
      />
      <PricingBlock
        services={arms}
        title="Nega ruku"
        link="/tretmani/nega-ruku"
        search={search}
      />
      <PricingBlock
        services={nails}
        title="Nokti"
        link="/tretmani/nega-ruku"
        search={search}
      />
      <PricingBlock
        services={additional}
        // link="/tretmani/nega-ruku"
        title="Dodatne usluge"
        search={search}
      />
      <div className="absolute -left-[10px] top-0 h-[440px] opacity-50 -z-10">
        <img
          loading="lazy"
          className="shape-move"
          src={flower}
          alt="hipnotic kragujevac"
        ></img>
      </div>
    </div>
  );
};
