import { FaCity, FaEnvelope, FaLocationArrow, FaPhone } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Heart } from "./Heart";

export const MainFooter = () => {
  return (
    <footer className="px-8">
      <div className="max-w-screen-xl">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0 flex h-[80px]">
            <Link className="flex justify-center items-center py-4" to="/">
              <h1 className="text-5xl font-playfair mb-2 font-thin text-black">
                I
              </h1>
              <Heart className="w-16 fill-black h-16" />
              <div className="flex flex-col text-center">
                <h2 className="text-black text-lg font-bold text-center font-whisper ml-2 -mb-2">
                  beauty & massage centar
                </h2>
                <h1 className="text-4xl text-black border-black font-whisper border-b-2 font-bold">
                  Hipnotic
                </h1>
              </div>
            </Link>
          </div>
          <div className="grid gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold uppercase text-black font-lexend">
                Linkovi
              </h2>
              <ul className="text-gray-600">
                <li className="mb-4">
                  <Link
                    to="/cenovnik"
                    className="hover:text-accent font-lexend"
                  >
                    Cenovnik
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    to="/tretmani/masaze"
                    className="hover:text-accent font-lexend"
                  >
                    Masaže
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    to="/tretmani/tretmani-lica"
                    className="hover:text-accent font-lexend"
                  >
                    Tretmani lica
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    to="/tretmani/indiba"
                    className="hover:text-accent font-lexend"
                  >
                    Indiba tretmani
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    to="/tretmani/pedikir"
                    className="hover:text-accent font-lexend"
                  >
                    Pedikir
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    to="/tretmani/nega-ruku"
                    className="hover:text-accent font-lexend"
                  >
                    Nega ruku i manikir
                  </Link>
                </li>
                <li>
                  <Link
                    to="/najcesca-pitanja"
                    className="hover:text-accent font-lexend"
                  >
                    Najčešća pitanja
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold uppercase text-black font-lexend">
                Radno vreme
              </h2>
              <ul className="text-gray-600">
                <li className="mb-4 font-lexend">Radni dani</li>
                <li className="mb-4 font-lexend">09-19h</li>
                <li className="mb-4 font-lexend">Subota</li>
                <li className="font-lexend">09-15h</li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold uppercase text-black font-lexend">
                Kontakt
              </h2>
              <ul className="text-gray-600">
                <li className="mb-4">
                  <a
                    href="tel:0631129917"
                    className="hover:underline flex items-center hover:text-accent decoration-accent font-lexend"
                  >
                    <FaPhone className="mr-2" />
                    063 112 99 17
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:info@hipnotic.rs"
                    className="hover:underline flex items-center hover:text-accent decoration-accent font-lexend"
                  >
                    <FaEnvelope className="mr-2 shrink-0" />
                    info@hipnotic.rs
                  </a>
                </li>
                <li className="my-4 flex items-center font-lexend">
                  <FaLocationArrow className="mr-2" />
                  Save Badnjevca 8
                </li>
                <li className="font-lexend flex items-center">
                  <FaCity className="mr-2" />
                  Kragujevac
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
