import classNames from "classnames";
import React, { useState } from "react";
import { FaPhone } from "react-icons/fa";
import patternImage from "../assets/indiba/background.png";

export const FAQ = () => {
  const [openItem, setOpenItem] = useState(0);

  const toggleItem = (index) => {
    setOpenItem(openItem === index ? null : index);
  };
  const items = [
    {
      title: "Gde se nalazimo?",
      content:
        "Naš studio se nalazi u ulici Save Badnjevca br. 8, u Kragujevcu.",
    },
    {
      title: "Koje nam je radno vreme?",
      content:
        "Otvoreni smo radnim danima od 09h do 19h. Subotom od 09h do 15h. Nedeljom ne radimo.",
    },
    {
      title: "Koje usluge nudimo?",
      content:
        "Nudi širok spektar usluga, uključujući tretmane za lice, telo, manikir, pedikir, negu ruku, kao i druge tretmane lepote i wellness usluge.",
    },
  ];
  return (
    <div
      className="flex flex-col md:flex-row md:justify-center md:gap-8 pb-12 px-8"
      style={{ backgroundImage: `url(${patternImage})` }}
    >
      <div className="md:w-2/6 w-full flex flex-col">
        <div className="bg-accentonlight p-4 md:p-8">
          <div className="text-2xl font-lexend text-white mb-2 md:mb-8">
            Imate pitanja?
            <br /> Pozovite odmah!
          </div>
          <a
            href="tel:0631129917"
            className="flex gap-4 items-center cursor-pointer"
          >
            <div className="border-white border rounded-full grid items-center justify-center w-12 h-12">
              <FaPhone className="fill-white" />
            </div>
            <span className="text-white font-lexend text-xl">
              063 112 99 17
            </span>
          </a>
        </div>
      </div>
      <div className="w-full md:w-4/6 h-full min-h-[250px]">
        <div className="w-full max-w-full">
          {items.map((item, index) => (
            <div key={index} className="border border-gray-200">
              <div
                className="flex justify-between items-center px-4 py-3 cursor-pointer bg-white"
                onClick={() => toggleItem(index)}
              >
                <h2
                  className={classNames("text-lg font-semibold font-lexend", {
                    "text-accentonlight": openItem === index,
                  })}
                >
                  {item.title}
                </h2>
                <span>{openItem === index ? "-" : "+"}</span>
              </div>
              <div
                className={`bg-white px-4 transition-all duration-500 ease-in-out font-lexend ${
                  openItem === index ? "py-2 max-h-screen" : "max-h-0"
                } overflow-hidden`}
              >
                {openItem === index && <p>{item.content}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
