import React, { useEffect } from "react";
import { NavBar } from "./NavBar";
import { MainFooter } from "./MainFooter";
import { Footer } from "./Footer";
import { Map } from "./Map";
import ScrollToTop from "./ScrollToTop";
import { Cursor } from "./Cursor";
import { useLocation } from "react-router-dom";
import Popup from "./Popup";

export const Layout = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);

    return () => clearTimeout(timeout);
  }, [pathname]);
  return (
    <>
      <NavBar />
      <Popup />
      {children}
      <ScrollToTop />
      <Cursor />
      <Map />
      <MainFooter />
      <Footer />
    </>
  );
};
