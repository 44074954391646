import React, { useState, useEffect } from "react";
import { FaPhone } from "react-icons/fa";
import "./Popup.css";

const Popup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const [shown, setShown] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPopup(true);
      setAnimationClass("slide-in");
    }, 45000);

    return () => clearTimeout(timeout);
  }, []);

  const closePopup = () => {
    setAnimationClass("slide-out");
    setShown(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 15000);
  };

  if (shown) return null;

  return (
    <div
      className={`fixed top-0 left-0 z-[100] p-4 popup-container max-w-[345px] ${
        showPopup ? "visible" : "hidden"
      } ${animationClass}`}
    >
      <div className="bg-white rounded-lg shadow-lg">
        <div className="flex justify-end items-center px-4 py-2 bg-gray-200 rounded-t-lg">
          <button
            className="text-accentonlight font-extrabold"
            onClick={closePopup}
          >
            &times; <span className="font-medium text-gray-600">Zatvori</span>
          </button>
        </div>
        <div className="p-4">
          <h2 className="text-xl font-semibold font-lexend">
            <span className="text-accentonlight">Hipnotic</span> - Beauty & Spa
          </h2>
          <p className="text-gray-700 font-lexend mt-2">
            Zainteresovani ste? Pozovite nas odmah i pružite Vašem telu ono što
            zaslužuje!
          </p>
          <a
            href="tel:0631129917"
            className="bg-accentonlight font-bold text-white px-4 py-2 rounded mt-4 inline-flex items-center"
          >
            <FaPhone className="mr-2" />
            063 112 99 17
          </a>
        </div>
      </div>
    </div>
  );
};

export default Popup;
