import React from "react";
import { BackLink } from "../../components/BackLink";
import { ServiceHeader } from "../../components/ServiceHeader";
import { BetterCta } from "../../components/BetterCta";
import { ServiceDetail } from "../../components/ServiceDetail";
import { PricingBlock } from "../../components/PricingBlock";
import { Link } from "react-router-dom";
import img1 from "../../assets/masaze/masaza1.jpg";
import img3 from "../../assets/masaze/masaza3.png";
import img4 from "../../assets/kedrovasauna.jpg";

export const FaceMassagePage = () => {
  const imgs = [img3, img1, img4];
  const masaze = [
    {
      title: "Masaža 30'",
      price: "1300 RSD",
      description: "",
    },
    {
      title: "Masaža 60'",
      price: "2500 RSD",
      description: "",
    },
    {
      title: "Masaža trudnica",
      price: "2000 RSD",
      description: "",
    },
    {
      title: "Anticelulitna masaža",
      price: "1400 RSD",
      description: "",
    },
    {
      title: "Masaža Terapeutska 30'",
      price: "1500 RSD",
      description: "",
    },
    {
      title: "Masaža terapeutska 60'",
      price: "3000 RSD",
      description: "",
    },
    {
      title: "Limfna drenaža",
      price: "1400 RSD",
      description: "",
    },
    {
      title: "Maderoterapija",
      price: "1400 RSD",
      description: "",
    },
    {
      title: "Vakum masaža",
      price: "1400 RSD",
      description: "",
    },
    {
      title: "Piling celog tela",
      price: "2000 RSD",
      description: "",
    },
    {
      title: "Tesla body former",
      price: "2000 RSD",
      description: "",
    },
    {
      title: "Kavitacija",
      price: "1500 RSD",
      description: "",
    },
    {
      title: "Presoterapija",
      price: "1500 RSD",
      description: "",
    },
    {
      title: "Orient thermo tretman",
      price: "2000 RSD",
      description: "",
    },
    {
      title: "Kedrova sauna",
      price: "1500 RSD",
      description: "",
    },
  ];

  const text =
    "Masaže koje nudimo obuhvataju različite tehnike i pristupe kako bi se postigla optimalna relaksacija, smanjenje napetosti i poboljšanje cirkulacije. Bez obzira da li ste u potrazi za brzim opuštanjem od 30 minuta ili dubljim terapeutskim tretmanom od 60 minuta, naše masaže su prilagođene vašim potrebama. Inovativni tretmani kao što su Tesla body former, kavitacija i presoterapija nude savremene pristupe oblikovanju tela i poboljšanju cirkulacije, dok orient thermo tretman i kedrova sauna pružaju toplotnu terapiju koja dodatno opušta i revitalizuje telo. Naši tretmani su idealni kao deo relaksacione rutine ili kao dopuna drugim tretmanima za postizanje optimalnog stanja tela i uma. Prepustite se našim stručnim terapeutima i doživite potpunu harmoniju i osveženje.";
  return (
    <div className="pt-[72px]">
      <ServiceHeader name="Masaže" />
      <BackLink />
      <ServiceDetail name="Masaže" text={text} imgs={imgs} />
      <div className="px-8 mt-12">
        <PricingBlock services={masaze} title="Masaže" />
      </div>
      <div className="px-8 mt-8 mb-12">
        <Link
          to="/cenovnik"
          className="border-2 border-accent text-accent px-4 py-2 rounded font-bold"
        >
          Pogledajte sve usluge
        </Link>
      </div>
      <BetterCta />
    </div>
  );
};
