import React from "react";
// import { FaPlay } from "react-icons/fa";
import { Title } from "./Title";
import flower from "../assets/hipnotic-pozadina.png";
import { Slider } from "./Slider";
import img1 from "../assets/slike/hipnotic16.webp";
import img2 from "../assets/slike/hipnotic18.webp";
import img3 from "../assets/slike/hipnotic20.webp";

export const OurStory = () => {
  const dummyImgs = [img1, img2, img3];
  // const [isPlaying, setIsPlaying] = useState(false);
  // const videoRef = useRef(null);

  // const toggleVideo = () => {
  //   if (isPlaying) {
  //     videoRef.current.pause();
  //     setIsPlaying(false);
  //   } else {
  //     videoRef.current.play();
  //     setIsPlaying(true);
  //   }
  // };

  return (
    <div
      className="flex flex-col md:flex-row items-center justify-center bg-smoke py-16 md:h-screen gap-4"
      id="our_story"
    >
      <div className="md:w-1/2 md:pr-4 px-8">
        <Title title="Naša" rest="priča" />
        <p className="text-gray-600 text-lg mb-4 font-lexend my-8">
          Mi smo <span className="text-accentonlight">Hipnotic</span> - beauty &
          spa centar. Od 2005. godine, posvećeni smo pružanju vrhunskih
          kozmetičkih usluga i relaksirajućih masaža. Naša reputacija temelji se
          na uspešnom poslovanju i zadovoljstvu velikog broja klijenata. Naš
          cilj je da vam pružimo nezaboravno iskustvo lepote, relaksacije i
          opuštanja. Kontaktirajte nas danas da biste zakazali svoj termin i
          uživali u hipnotičkom luksuzu koje Vaše telo zaslužuje. Zakažite Vaš
          tretman već danas tako što ćete nam pisati na email{" "}
          <span className="text-accentonlight">info@hipnotic.rs</span> ili nas
          pozvati na{" "}
          <span className="text-accentonlight">+381 63 112 99 17</span>.
        </p>
        <div className="mt-12">
          <a
            href="tel:0631129917"
            className="border border-accent text-accentonlight px-4 py-2 rounded font-medium hover:bg-accent hover:text-white"
          >
            063 112 99 17
          </a>
        </div>
      </div>
      {/* <div className="w-full md:w-1/2 md:pr-8 relative">
        <div className="hidden md:flex absolute -left-[40px] -top-[100px] h-[440px] opacity-70">
          <img
            className="shape-move"
            src={flower}
            alt="hipnotic kragujevac"
          ></img>
        </div>
        <div className="relative w-full h-96 md:rounded-full overflow-hidden shadow-lg">
          <video
            className="absolute inset-0 w-full h-full object-cover z-50"
            // src={video}
            onClick={toggleVideo}
            ref={videoRef}
          >
            Your browser does not support the video tag.
          </video>
          {!isPlaying ? (
            <div
              className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center cursor-pointer"
              onClick={toggleVideo}
            >
              <button className="w-16 h-16 rounded-xl bg-gray-800 bg-opacity-75 flex items-center justify-center">
                <FaPlay className="w-8 h-8 text-white" />
              </button>
            </div>
          ) : null}
        </div>
      </div> */}
      <div className="w-full md:w-1/2 md:pl-4 relative">
        <div className="hidden md:flex absolute -left-[40px] -top-[100px] h-[408px] opacity-70">
          <img
            className="shape-move"
            src={flower}
            width={373}
            height={408}
            loading="lazy"
            alt="hipnotic kragujevac"
          ></img>
        </div>
        <Slider imgs={dummyImgs} hideControls />
      </div>
    </div>
  );
};
