import React from "react";
import { Title } from "./Title";
import { Slider } from "./Slider";
import img1 from "../assets/slike/hipnotic16.webp";
import img2 from "../assets/slike/hipnotic18.webp";
import img3 from "../assets/slike/hipnotic20.webp";
import flower from "../assets/hipnotic-pozadina.png";

export const ServiceDetail = ({ name, text, imgs }) => {
  const dummyImgs = imgs.length ? imgs : [img1, img2, img3];
  return (
    <div
      className="flex flex-col md:flex-row items-center justify-center bg-smoke py-16 md:h-screen gap-14 md:gap-4"
      id="our_story"
    >
      <div className="md:w-1/2 md:pr-4 px-8">
        <Title title={name} />
        <p className="text-gray-600 text-lg mb-4 font-lexend my-8">
          {text} Zakažite Vaš tretman već danas tako što ćete nam pisati na
          email <span className="text-accent">info@hipnotic.rs</span> ili nas
          pozvati na <span className="text-accent">+381 63 112 99 17</span>.
        </p>
        <div className="mt-12">
          <a
            href="tel:0631129917"
            className="border border-accent text-accent px-4 py-2 rounded font-medium hover:bg-accent hover:text-white"
          >
            063 112 99 17
          </a>
        </div>
      </div>

      <div className="w-full md:w-1/2 md:pl-4 relative">
        <div className="hidden md:flex absolute -left-[40px] -top-[100px] h-[408px] opacity-70">
          <img
            loading="lazy"
            className="shape-move"
            width={373}
            height={408}
            src={flower}
            alt="hipnotic kragujevac"
          ></img>
        </div>
        <Slider imgs={dummyImgs} hideControls />
      </div>
    </div>
  );
};
