import React from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import patternImage from "../assets/indiba/background.png";

export const BackLink = () => {
  return (
    <Link
      className="flex gap-2 hover:text-accent items-center px-8 font-lexend py-8 decoration-none bg-repeat bg-center"
      to="/"
      style={{ backgroundImage: `url(${patternImage})` }}
    >
      <FaLongArrowAltLeft /> Nazad na početnu stranu
    </Link>
  );
};
