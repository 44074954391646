import React from "react";
import { BackLink } from "../../components/BackLink";
import { ServiceHeader } from "../../components/ServiceHeader";
import { BetterCta } from "../../components/BetterCta";
import { ServiceDetail } from "../../components/ServiceDetail";
import { PricingBlock } from "../../components/PricingBlock";
import { Link } from "react-router-dom";
import img1 from "../../assets/indiba/indiba4.jpg";
import img2 from "../../assets/indiba/indiba2.webp";
import img3 from "../../assets/indiba/indiba3.webp";
import img4 from "../../assets/indiba/indiba1.webp";

export const IndibaPage = () => {
  const imgs = [img1, img4, img2];

  const indibaDeepBeauty = [
    {
      title: "Indiba lice",
      price: "6000 RSD",
      description: "Trajanje 90 minuta po tretmanu.",
    },
    {
      title: "Indiba lice-vrat-dekolte",
      price: "8000 RSD",
      description: "Trajanje 90 minuta po tretmanu.",
    },
    {
      title: "Indiba očna zona",
      price: "4000 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
    {
      title: "Indiba podbradak",
      price: "4000 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
    {
      title: "Indiba kosa",
      price: "6000 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
  ];

  const indibaTelo = [
    {
      title: "Indiba noge",
      price: "6000 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Indiba stomak",
      price: "4000 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Indiba ruke",
      price: "4000 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
    {
      title: "Indiba noge-stomak",
      price: "8000 RSD",
      description: "Trajanje 90 minuta po tretmanu.",
    },
    {
      title: "Indiba kolena",
      price: "4000 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
    {
      title: "Indiba gluteusi",
      price: "5000 RSD",
      description: "Trajanje 45 minuta po tretmanu.",
    },
  ];

  const text =
    "INDIBA tehnologija nudi inovativne tretmane za podmlađivanje lica, zatezanje kože, podmlađivanje tela, tretmane za kosu, i spa tretmane, koristeći napredne tehnologije za regeneraciju i revitalizaciju. INDIBA tretmani koriste napredne tehnologije kako bi pružili vrhunske rezultate u nezi kože i tela. Preporučujemo redovne tretmane kao deo vaše rutine nege kako biste postigli blistav i svež izgled.";
  return (
    <div className="pt-[72px]">
      <ServiceHeader name="Indiba" hero={img3} />
      <BackLink />
      <ServiceDetail name="Indiba" text={text} imgs={imgs} />
      <div className="px-8 mt-12">
        <PricingBlock services={indibaDeepBeauty} title="Indiba Deep Beauty" />
        <PricingBlock services={indibaTelo} title="Indiba telo" />
      </div>
      <div className="px-8 mt-8 mb-12">
        <Link
          to="/cenovnik"
          className="border-2 border-accent text-accent px-4 py-2 rounded font-bold"
        >
          Pogledajte sve usluge
        </Link>
      </div>
      <BetterCta />
    </div>
  );
};
