import React from "react";
import "./HeroHeader.css";

export const HeroHeader = () => {
  return (
    <div
      className="relative bg-cover bg-center h-screen overflow-hidden"
      style={{
        backgroundImage: "url('/assets/hipnoticHero.webp')",
        backgroundPosition: "bottom",
      }}
    >
      <div
        className="absolute bottom-0 left-0 w-full h-[100%] bg-repeat-x max-w-full"
        style={{
          backgroundImage: "url(/assets/hipnoticsmoke.png)",
          animation: "cloudMove 60s linear 0s infinite",
          zIndex: 10,
          backgroundSize: "cover",
          backgroundPositionX: "50%",
          backgroundPositionY: "50%",
          bottom: "-356px",
        }}
      ></div>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative text-white flex flex-col items-center justify-center h-screen">
        <h1 className="text-8xl font-whisper text-accent text-center border-0 border-b border-white animate__animated animate__fadeInUp">
          Hipnotic
        </h1>
        <p className="mt-4 text-xl mx-8 text-center font-lexend text-2xl max-w-[650px] my-4 animate__animated animate__fadeInUp">
          BEAUTY & SPA CENTER
        </p>
        <p className="mt-4 text-xl mx-8 text-center font-lexend text-2xl max-w-[650px] my-4 animate__animated animate__fadeInUp">
          Opustite se u miru našeg centra lepote i masaže, gde se brinemo o
          Vašem opuštanju i osveženju
        </p>
        <a
          href="tel:0631129917"
          className="mt-4 px-4 py-2 bg-black border border-white hover:bg-accent font-medium rounded z-10 animate__animated animate__fadeInUp"
        >
          063 112 99 17
        </a>
      </div>
    </div>
  );
};
