import React from "react";

import { HeroHeader } from "../components/HeroHeader";
import { OurStory } from "../components/OurStory";
import { Gallery } from "../components/Gallery";
import { UserReview } from "../components/UserReview";
import OurTeam from "../components/OurTeam";
import { BetterCta } from "../components/BetterCta";
import { FAQ } from "../components/FAQ";
import { WishPro } from "../components/WishPro";
import { FeaturedService } from "../components/FeaturedService";
import { BodyFormer } from "../components/BodyFormer";
import { PartnerShowcase } from "../components/PartnerShowcase";

export const Homepage = () => {
  return (
    <>
      <HeroHeader />
      <OurStory />
      <FeaturedService />
      <BodyFormer />
      <WishPro />
      <UserReview />
      <BetterCta />
      <OurTeam />
      <PartnerShowcase />
      <FAQ />
      <Gallery />
    </>
  );
};
