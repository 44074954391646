import React from "react";
import { BackLink } from "../../components/BackLink";
import { ServiceHeader } from "../../components/ServiceHeader";
import { BetterCta } from "../../components/BetterCta";
import { ServiceDetail } from "../../components/ServiceDetail";
import { PricingBlock } from "../../components/PricingBlock";
import { Link } from "react-router-dom";
import img1 from "../../assets/pedikir/pedikir1.jpg";
import img2 from "../../assets/pedikir/pedikir2.jpg";
import img3 from "../../assets/pedikir/pedikir3.jpg";
import img4 from "../../assets/pedikir/pedikir4.jpg";

export const PedicurePage = () => {
  const imgs = [img1, img3, img2];

  const negaStopala = [
    {
      title: "Estetski pedikit",
      price: "2000 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Estetski pedikir-gel lak",
      price: "2500 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Medicinski pedikir 1",
      price: "2500 RSD",
      description: "Trajanje 90 minuta po tretmanu.",
    },
    {
      title: "Medicinski pedikir 2",
      price: "3000 RSD",
      description: "Trajanje 90 minuta po tretmanu.",
    },
    {
      title: "Medicinski pedikir-gel lak",
      price: "3000 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Antimikotik pedikir",
      price: "3500 RSD",
      description: "Trajanje 90 minuta po tretmanu.",
    },
    {
      title: "Antimikotik obrada noktiju",
      price: "2500 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Saniranje uraslog nokta",
      price: "1000 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "Saniranje žulja, kurjeg oka",
      price: "1000 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "Ortoksija proteza 1 uraslog nokta",
      price: "3000 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Tamponada silikonska",
      price: "1000 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "Gel lak na noktima",
      price: "1500 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
    {
      title: "Obrada noktiju",
      price: "1000-1500 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "Obrada stopala",
      price: "1000-1500 RSD",
      description: "Trajanje 30 minuta po tretmanu.",
    },
    {
      title: "Lakiranje noktiju",
      price: "500 RSD",
      description: "Trajanje 60 minuta po tretmanu.",
    },
  ];

  const text =
    "Naša ponuda pedikir tretmana pruža širok spektar usluga za negu i estetsko unapređenje stopala. Koristimo najnovije tehnike i proizvode kako bismo obezbedili vrhunske rezultate i zadovoljstvo naših klijenata. Redovni pedikir tretmani ne samo da unapređuju izgled stopala već i doprinose njihovom zdravlju i udobnosti. Naši pedikir tretmani su dizajnirani da zadovolje različite potrebe i preferencije, pružajući vam osećaj svežine i nege. Preporučujemo redovne tretmane kao deo vaše rutine nege kako biste postigli i održali zdravlje i lepotu vaših stopala.";
  return (
    <div className="pt-[72px]">
      <ServiceHeader name="Pedikir" hero={img4} />
      <BackLink />
      <ServiceDetail name="Pedikir" text={text} imgs={imgs} />
      <div className="px-8 mt-12">
        <PricingBlock services={negaStopala} title="Pedikir" />
      </div>
      <div className="px-8 mt-8 mb-12">
        <Link
          to="/cenovnik"
          className="border-2 border-accent text-accent px-4 py-2 rounded font-bold"
        >
          Pogledajte sve usluge
        </Link>
      </div>
      <BetterCta />
    </div>
  );
};
