import React from "react";
import { BackLink } from "../../components/BackLink";
import { ServiceHeader } from "../../components/ServiceHeader";
import { BetterCta } from "../../components/BetterCta";
import { ServiceDetail } from "../../components/ServiceDetail";
import { PricingBlock } from "../../components/PricingBlock";
import { Link } from "react-router-dom";
import img2 from "../../assets/slike/hipnotic16.webp";
import img3 from "../../assets/slike/hipnotic8.jpg";
import img4 from "../../assets/tretmanilicanova.jpg";
import img5 from "../../assets/tretmanilicabg.jpg";

export const FacialTreatmentsPage = () => {
  const imgs = [img4, img2, img3];
  const tretmaniLica = [
    {
      title: "Higijenski tretman",
      price: "3500 RSD",
      description: "",
    },
    {
      title: "Higijenski tretman sa fitopilingom",
      price: "4500 RSD",
      description: "",
    },
    {
      title: "Higijenski sa hemijskim pilingom",
      price: "4000 RSD",
      description: "",
    },
    {
      title: "Fitopiling",
      price: "4000 RSD",
      description: "",
    },
    {
      title: "Radiotalasni lifting",
      price: "3500 RSD",
      description: "",
    },
    {
      title: "Mezoterapija",
      price: "3500 RSD",
      description: "",
    },
    {
      title: "Hydrafacial",
      price: "4500 RSD",
      description: "",
    },
    {
      title: "Ultrazvučni piling",
      price: "2500 RSD",
      description: "",
    },
    {
      title: "Oxygen tretman",
      price: "4500 RSD",
      description: "",
    },
    {
      title: "Derma pen",
      price: "6000 RSD",
      description: "",
    },
    {
      title: "Prx-t33",
      price: "6000 RSD",
      description: "",
    },
    {
      title: "Retinol stop akne",
      price: "4000 RSD",
      description: "",
    },
    {
      title: "Retinol anti age",
      price: "6000 RSD",
      description: "",
    },
    {
      title: "Wish pro",
      price: "7000 RSD",
      description: "",
    },
    {
      title: "Zk peeling",
      price: "5000 RSD",
      description: "",
    },
    {
      title: "Hydra intesive",
      price: "4500 RSD",
      description: "",
    },
    {
      title: "Masaža lica",
      price: "1500 RSD",
      description: "",
    },
    {
      title: "Korekcija obrva",
      price: "600 RSD",
      description: "",
    },
    {
      title: "Depilacija nausnica",
      price: "200 RSD",
      description: "",
    },
  ];
  const text =
    "Tretmani lica su sveobuhvatni procesi koji se fokusiraju na održavanje i poboljšanje zdravlja i izgleda kože lica. Ovi tretmani mogu uključivati različite tehnike i proizvode kako bi se postigao optimalan rezultat, poput higijenskih tretmana, pilinga i drugih tretmana prilagođenih potrebama kože. Cilj im je osvežiti, hidrirati, i revitalizovati kožu, istovremeno poboljšavajući njen tonus i teksturu. Tretmani lica se često prilagođavaju individualnim potrebama i ciljevima kako bi se postigao najbolji mogući efekat za svaku osobu.";
  return (
    <div className="pt-[72px]">
      <ServiceHeader name="Tretmani lica" hero={img5} />
      <BackLink />
      <ServiceDetail name="Tretmani lica" text={text} imgs={imgs} />
      <div className="px-8 mt-12">
        <PricingBlock services={tretmaniLica} title="Tretmani lica" />
      </div>
      <div className="px-8 mt-8 mb-12">
        <Link
          to="/cenovnik"
          className="border-2 border-accent text-accent px-4 py-2 rounded font-bold"
        >
          Pogledajte sve usluge
        </Link>
      </div>
      <BetterCta />
    </div>
  );
};
