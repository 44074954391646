import React from "react";
import img1 from "../assets/izdvojeno/bodiformer.webp";

export const BodyFormer = () => {
  const service = {
    title: "Prvi i jedini u Kragujevcu - Body Former Pro",
    description:
      "Tretman u trajanju od 30 minuta ima učinak poput 30,000 trbušnjaka ili 30,000 čučnjeva, a sve to dok opušteno ležite. ️Body Former je prvi tretman koji pomaže ženama i muškarcima da izgrade mišiće i oblikuju telo.",
    ctaText: "Book Now",
    ctaLink: "#book",
  };

  return (
    <section className="relative bg-gray-800 text-white">
      <img
        src={img1}
        alt={service.title}
        loading="lazy"
        className="absolute inset-0 w-full h-full object-cover opacity-20 background-top"
      />
      <div className="relative container mx-auto px-6 py-36 flex flex-col items-center text-center">
        <h1 className="text-5xl font-thin font-lexend text-white mb-12">
          <span className="font-whisper text-accent text-7xl pt-4">
            Prvi i jedini u Kragujevcu
          </span>
          <br />
          Body Former Pro
        </h1>
        <p className="text-xl mb-12 font-lexend">{service.description}</p>
        <a
          href="tel:0631129917"
          className="border border-accent text-accent px-4 py-2 rounded font-medium hover:bg-accent hover:text-white"
        >
          063 112 99 17
        </a>
      </div>
    </section>
  );
};
