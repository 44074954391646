import React from "react";
import PropTypes from "prop-types";
import "./ImagesGrid.css";

export const ImagesGrid = ({ images, selected, onSelect }) => {
  return (
    <div className="ig-image-gallery">
      <div className="ig-main-image">
        <img
          src={images[selected - 1].src}
          alt={images[selected - 1].alt}
          loading="lazy"
        />
      </div>
      <div className="ig-thumbnails">
        {images.map((image, index) => (
          <img
            key={image.src}
            src={image.src}
            loading="lazy"
            alt={image.alt}
            className={`ig-thumbnail ${
              selected === index + 1 ? "selected" : ""
            }`}
            onClick={() => onSelect(index + 1)}
          />
        ))}
      </div>
    </div>
  );
};

ImagesGrid.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
  selected: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};
