import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { Title } from "./Title";
import "./UserReview.css";
import img1 from "../assets/recenzije/hipnoticreview1.png";
import img2 from "../assets/recenzije/hipnoticreview2.png";
import img3 from "../assets/recenzije/hipnoticreview3.png";
import img4 from "../assets/recenzije/hipnoticreview4.png";
import img5 from "../assets/recenzije/hipnoticreview5.png";
import img6 from "../assets/recenzije/hipnoticreview6.png";
import fb1 from "../assets/recenzije/fb1.jpg";
import fb2 from "../assets/recenzije/fb2.jpg";
import fb3 from "../assets/recenzije/fb3.jpg";

export const UserReview = () => {
  const reviews = [
    {
      id: 1,
      content:
        "Savršene devojke, dovode naša lica do savršenstva 🌞 Toplo se preporučuje za sve tretmane lica i mršavljenja",
      author: "Jelena Kostic",
      position: "Google recenzija",
      avatar: img1,
    },
    {
      id: 2,
      content:
        "Prijatan, profesionalan, ugodan ambijent. Sve pohvale za njih!!!",
      author: "Aleksandra Spiljevic",
      position: "Google recenzija",
      avatar: img3,
    },
    {
      id: 3,
      content:
        "Veoma lepe dame koje postižu veoma dobre rezultate... znaju tačno šta rade",
      author: "Aram Mahmudl",
      position: "Google recenzija",
      avatar: img4,
    },
    {
      id: 4,
      content: "ne menjam ih 15 godina...",
      author: "Marija Radulović",
      position: "Facebook recenzija",
      avatar: fb1,
    },
    {
      id: 5,
      content:
        "Toplo preporučujem ❤ Izuzetna pozitivna energija predivnih devojaka leči dušu i telo ❤",
      author: "Vesna Ranković",
      position: "Facebook recenzija",
      avatar: fb2,
    },
    {
      id: 6,
      content: "Vrhunski kvalitet i profesionalnost",
      author: "Jasminka Vojinović",
      position: "Facebook recenzija",
      avatar: fb3,
    },
    {
      id: 7,
      content: "Perfekcionisti u svakom smislu ❤",
      author: "Jelena Joksimovic",
      position: "Google recenzija",
      avatar: img2,
    },
    {
      id: 8,
      content: "Preporuka!",
      author: "Sonja Kesic",
      position: "Google recenzija",
      avatar: img5,
    },
    {
      id: 9,
      content: "Odlična masaža",
      author: "Branimir Dženopoljac",
      position: "Google recenzija",
      avatar: img6,
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [animationClass, setAnimationClass] = useState("");

  const updateScreenSize = () => {
    setScreenSize(window.innerWidth);
    setCurrentIndex(0);
  };

  window.addEventListener("resize", updateScreenSize);
  const reviewsPerPage = screenSize < 600 ? 1 : screenSize < 1020 ? 2 : 3;

  useEffect(() => {
    const interval = setInterval(() => {
      updateScreenSize();
      const nextIndex = currentIndex + reviewsPerPage;
      if (nextIndex >= reviews.length) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(nextIndex);
      }
      setAnimationClass("slide-left");
    }, 6000);

    return () => clearInterval(interval);
  }, [currentIndex, reviews.length, reviewsPerPage]);

  const visibleReviews = reviews.slice(
    currentIndex,
    currentIndex + reviewsPerPage
  );

  return (
    <div className="relative px-8 py-8 bg-smoke md:h-screen overflow-hidden">
      <Title title="Naši" rest="klijenti" className="mt-8" />
      <p className="text-gray-600 text-lg mb-4 font-lexend mt-8 mb-14 text-justify">
        Najbolji pokazatelj uspešnosti je zadovoljstvo naših korisnika. Svojom
        predanošću i pažnjom prema svakom detalju, nastojimo da svaki korisnik
        ostvari svoje ciljeve i budemo deo njihovog uspeha. Ove recenzije su
        samo deo šire slike koju stvaramo svakodnevno, prateći trendove i težeći
        ka najboljem iskustvu za naše korisnike. Evo šta neki od njih imaju da
        kažu o našoj usluzi:
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
        {visibleReviews.map((review, index) => (
          <div
            key={review.id}
            className={`flex flex-col justify-between rounded-md border border-neutral-200 bg-white p-8 shadow-sm max-w-full mx-auto w-full min-h-[300px] shadow-xl ${animationClass}`}
          >
            <div className="text-violet-500 flex gap-2">
              {[...Array(5).keys()].map((star) => (
                <FaStar key={star} className="text-accent" />
              ))}
            </div>

            <p className="my-4 mb-0 text-base font-normal leading-relaxed tracking-wide text-black font-lexend">
              {review.content}
            </p>

            <div className="mt-6 flex items-center gap-6 ">
              <div className="h-10 w-10 overflow-hidden rounded-full shadow-sm outline-neutral-800">
                <div className="relative inline-block overflow-hidden rounded-lg border-neutral-800">
                  <img
                    alt=""
                    src={review.avatar}
                    width="50"
                    height="50"
                    decoding="async"
                    data-nimg="1"
                    className="inline-block"
                    loading="lazy"
                    style={{ color: "transparent" }}
                  />
                </div>
              </div>
              <div>
                <p className="leading-relaxed tracking-wide text-accent font-lexend">
                  {review.author}
                </p>
                <p className="text-xs leading-relaxed tracking-wide text-gray-600 font-lexend">
                  {review.position}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-12">
        {Array.from(
          { length: Math.ceil(reviews.length / reviewsPerPage) },
          (_, index) => (
            <button
              key={index}
              aria-label="Slide Indicator"
              onClick={() => setCurrentIndex(index * reviewsPerPage)}
              className={`mx-1 w-4 h-4 rounded-full ${
                currentIndex === index * reviewsPerPage
                  ? "bg-accent"
                  : "bg-gray-400"
              }`}
            />
          )
        )}
      </div>
    </div>
  );
};
