import React from "react";
import { Header } from "./Header";
import { useNavigate } from "react-router-dom";

export const PricingBlock = ({
  services,
  title: titleProp,
  search = "",
  link,
}) => {
  const navigate = useNavigate();
  const highlightMatch = (text) => {
    const searchTerm = search.toLowerCase();
    const regex = new RegExp(`(${searchTerm})`, "gi");
    return text.replace(regex, "<mark>$1</mark>");
  };

  const filteredServices = services.filter((service) => {
    const searchTerm = search.toLowerCase();
    const titleIncludesSearchTerm = titleProp
      .toLowerCase()
      .includes(searchTerm);
    if (titleIncludesSearchTerm) {
      return services;
    }
    const { title, price, description } = service;
    return (
      title.toLowerCase().includes(searchTerm) ||
      price.toLowerCase().includes(searchTerm) ||
      description.toLowerCase().includes(searchTerm)
    );
  });

  const handleServiceClick = () => {
    if (link) {
      navigate(link);
    }
  };

  if (filteredServices.length === 0) return null;

  return (
    <>
      <Header
        className={
          link
            ? "text-3xl mb-2 cursor-pointer hover:underline z-50"
            : "text-3xl mb-2 z-50"
        }
        onClick={handleServiceClick}
        dangerouslySetInnerHTML={{
          __html: highlightMatch(titleProp),
        }}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8">
        {filteredServices.map((service, index) => (
          <div key={index}>
            <div className="flex justify-between mb-2">
              <h3
                className="text-lg font-semibold font-lexend"
                dangerouslySetInnerHTML={{
                  __html: highlightMatch(service.title),
                }}
              />
              <p
                className="text-xl font-semibold text-accent font-lexend shrink-0"
                dangerouslySetInnerHTML={{
                  __html: highlightMatch(service.price),
                }}
              />
            </div>
            <p
              className="text-gray-600 mb-2 font-lexend"
              dangerouslySetInnerHTML={{
                __html: highlightMatch(service.description),
              }}
            />
            <hr className="my-4" />
          </div>
        ))}
      </div>
    </>
  );
};
