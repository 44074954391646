import React from "react";
import { BackLink } from "../components/BackLink";
import { ServiceHeader } from "../components/ServiceHeader";
import { BetterCta } from "../components/BetterCta";
import { Pricing } from "../components/Pricing";

export const PricingPage = () => {
  return (
    <div className="pt-[72px]">
      <ServiceHeader name="Cenovnik" />
      <BackLink />
      <Pricing />
      <BetterCta />
    </div>
  );
};
