import React from "react";
import { Title } from "./Title";

export const Map = () => {
  return (
    <div className="mt-4">
      <Title title="Kako" rest="do nas" className="px-8" />
      <div
        className="map-container mb-8"
        style={{ width: "100%", height: "400px", maxHeight: "400px" }}
      >
        <iframe
          title="Kako do nas"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11477.217562233775!2d20.9183163!3d44.015104!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475721270faddbdf%3A0x524d8f68e878f0a1!2sHIPNOTIC%20beauty%20and%20massage%20centar!5e0!3m2!1ssr!2srs!4v1717007443718!5m2!1ssr!2srs"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};
